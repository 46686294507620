var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shop-module"},[_c('div',{staticClass:"ot-card has-no-padding external-validation-form"},[(_vm.header)?_c('h1',{staticClass:"external-validation-form__header"},[_vm._v(" "+_vm._s(_vm.$t(_vm.header))+" ")]):_vm._e(),(_vm.header_description)?_c('p',{staticClass:"external-validation-form__header_description"},[_vm._v(" "+_vm._s(_vm.$t(_vm.header_description))+" ")]):_vm._e(),(_vm.choices && _vm.choices.length > 0)?_c('div',_vm._l((_vm.choices),function(choice){return _c('div',{key:choice.name},[_c('div',{staticClass:"ot-separator"}),_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () { return _vm.onSubmitChoice(choice.name); }).apply(null, arguments)}}},[_c('div',{staticClass:"external-validation-form__choice"},[(choice.description)?_c('p',{staticClass:"external-validation-form__choice__description"},[_vm._v(" "+_vm._s(_vm.$t(choice.description))+" ")]):_vm._e(),(choice.fields)?_c('div',_vm._l((Object.keys(
                                    choice.fields
                                )),function(fieldName){return _c('div',{key:fieldName,staticClass:"external-validation-form__choice__field"},[_c('ot-input-field',{attrs:{"label":choice.fields[fieldName].label
                                            ? _vm.$t(
                                                  choice.fields[fieldName]
                                                      .label || ''
                                              )
                                            : fieldName,"for":choice.name + '_' + fieldName,"error":_vm.error &&
                                            choice.target.error &&
                                            _vm.$t(choice.target.error)}},[_c('ot-input',{attrs:{"type":choice.fields[fieldName].type,"id":choice.name + '_' + fieldName,"disabled":_vm.loading > 0},on:{"input":function (value) { return _vm.onInputChoice(
                                                    choice.name,
                                                    fieldName,
                                                    value
                                                ); }}}),(
                                            choice.fields[fieldName].addon
                                        )?_c('div',{staticClass:"external-validation-form__choice__addon"},[(
                                                choice.fields[fieldName]
                                                    .addon.link
                                            )?_c('a',{staticClass:"ot-link ot-text-small ot-clickable",attrs:{"href":choice.fields[fieldName]
                                                    .addon.link,"target":"_blank","rel":"noreferrer noopener"}},[_vm._v(_vm._s(_vm.$t( choice.fields[fieldName] .addon.description )))]):_c('p',[_vm._v(" "+_vm._s(_vm.$t( choice.fields[fieldName] .addon.description ))+" ")])]):_vm._e()],1)],1)}),0):_vm._e(),_c('button',{staticClass:"ot-button is-fullwidth external-validation-form__choice__button",class:{ 'is-loading': _vm.loading > 0 },attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t(choice.button || ''))+" "),_c('i',{staticClass:"ot-button-icon--right oti oti-arrow-right"})])])])])}),0):_vm._e(),_c('div',{staticClass:"ot-separator"}),(_vm.footer)?_c('p',{staticClass:"external-validation-form__footer"},[_vm._v(" "+_vm._s(_vm.$t(_vm.footer))+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }