





















import {
    CartTicket,
    Product,
    ProductGroup,
    ShopProduct,
} from '@openticket/sdk-shop';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class OptionalProductGroupMultiSelectItem extends Vue {
    @Prop() ticket!: CartTicket;
    @Prop() productGroup!: ProductGroup;
    @Prop() max?: number;

    value: string[] = [];

    productListener!: string;

    created(): void {
        this.productListener = this.$shop.cart.on(
            ['ticket', this.ticket.id, 'product'],
            () => {
                this.updateValue();
            }
        );

        this.updateValue();
    }

    destroyed(): void {
        if (this.productListener) {
            this.$shop.cart.off(this.productListener);
        }
    }

    get options(): { [key: string]: string } {
        const map: { [key: string]: string } = {};
        for (const product of this.productGroup.products) {
            map[product.guid] = `${product.item.name} ( ${this.suffix(
                product.item
            )} )`;
        }
        return map;
    }

    suffix(product: Product): string {
        // The pricing should come from the ShopProduct wrapper,
        // the GroupProduct item's pricing is static,
        // for container products, the ShopProduct's pricing will change
        // depending on the products in the cart!
        const price = (
            this.ticket.item.products.all.find(
                (ticketProduct: ShopProduct) =>
                    ticketProduct.guid === product.guid
            ) || product
        ).pricing.total_price;

        return this.$l.currency(price, this.$shop.data.currency as string);
    }

    updateValue(): void {
        const value: string[] = [];
        for (const product of this.productGroup.products) {
            for (const cartProduct of this.ticket.products.collection) {
                if (product.guid === cartProduct.item.guid) {
                    value.push(product.guid);
                }
            }
        }
        this.value = value;
    }

    @Watch('value')
    onValueChanged(newValue: string[], oldValue: string[]): void {
        for (const guid of [...newValue, ...oldValue]) {
            if (!newValue.includes(guid)) {
                this.$emit('remove', guid);
            } else if (!oldValue.includes(guid)) {
                this.$emit('add', guid);
            }
        }
    }

    private maxWarning(max: string): string {
        return this.$t(
            'shop.components.option_product_group.multi_select_item.max_warning',
            { max }
        ) as string;
    }

    selectionLabel(values: unknown[], isOpen: boolean): string {
        if (values.length || isOpen) {
            return this.$tc(
                'shop.components.option_product_group.multi_select_item.selection_label',
                values.length
            ) as string;
        }

        return '';
    }
}
