





































































































import urlJoin from 'url-join';
import Component from 'vue-class-component';
import ShopModule from './module';
import { OpenTicketCookiesInjector } from '@openticket/lib-cookies';
import { Ref } from 'vue-property-decorator';

@Component
export default class FacebookPageCard extends ShopModule {
    /* SHOP MODULE */

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    @Ref('container')
    container!: HTMLDivElement;

    width = 0;

    cookies = new OpenTicketCookiesInjector();
    cookiesAccepted = false;

    // The iframe can be done loading even if it has an error.
    iframeDoneLoading = false;
    iframeHadError = false;

    async mounted(): Promise<void> {
        const preferences = await this.cookies.init();

        if (preferences && preferences.personalisedAds) {
            this.cookiesAccepted = true;
            this.$nextTick(() => {
                this.setWidth();
            });
        }
    }

    setWidth(): void {
        if (this.appId && this.url && this.cookiesAccepted) {
            this.width = this.container.clientWidth;
        }
    }

    get loaded(): boolean {
        return this.iframeDoneLoading && !this.iframeHadError;
    }

    iframeLoaded(): void {
        this.iframeDoneLoading = true;
    }

    iframeError(): void {
        this.iframeHadError = true;
    }

    get url(): string | null {
        return this.$shop.data.facebook_page_url;
    }

    get appId(): string | null {
        return this.$whitelabel.shop.facebook_client_id || null;
    }

    get facebookSrc(): string {
        // <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2F&tabs=timeline&width=500&height=72&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=some-id" width="500" height="72" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        return urlJoin([
            'https://www.facebook.com/plugins/page.php',
            `?href=${encodeURIComponent(urlJoin(`${this.url}/`))}`,
            '&tabs=timeline',
            `&width=${this.width}`,
            '&height=72',
            '&small_header=true',
            '&adapt_container_width=true',
            '&hide_cover=false',
            '&show_facepile=true',
            `&appId=${this.appId}`,
        ]);
    }
}
