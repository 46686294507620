


















































































































































































import ShopModuleComponent from '../../module';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ExternalValidationFormChoice } from './types';
import axios, { AxiosRequestConfig } from 'axios';
import urlJoin from 'url-join';

@Component
export default class ExternalValidationForm extends ShopModuleComponent {
    @Prop() header!: string | undefined;
    @Prop() header_description!: string | undefined;
    @Prop() footer!: string | undefined;

    @Prop() choices!: ExternalValidationFormChoice[];

    loading = 0;
    error = false;
    valueList: Record<string, string> = {};

    getChoiceByName(name: string): ExternalValidationFormChoice | undefined {
        return this.choices.find(c => c.name === name);
    }

    onInputChoice(choiceName: string, fieldName: string, value: string): void {
        this.valueList[choiceName + '.' + fieldName] = value;
    }

    onSubmitChoice(choiceName: string): void {
        const choice = this.getChoiceByName(choiceName);
        if (choice) {
            this.runChoice(choice);
        }
    }

    buildChoiceTargetConfig(
        choice: ExternalValidationFormChoice
    ): AxiosRequestConfig {
        const queryVars: Record<string, string> = {};
        const jsonVars: Record<string, string> = {};

        const fieldNames = Object.keys(choice.fields);
        let targetUrl = choice.target.url;

        fieldNames.forEach(fieldName => {
            switch (choice.fields[fieldName].send) {
                case 'path':
                    targetUrl = targetUrl.replace(
                        '{{' + fieldName + '}}',
                        this.valueList[choice.name + '.' + fieldName]
                    );
                    break;
                case 'json':
                    jsonVars[fieldName] = this.valueList[
                        choice.name + '.' + fieldName
                    ];
                    break;
                case 'query':
                    queryVars[fieldName] = this.valueList[
                        choice.name + '.' + fieldName
                    ];
                    break;
                default:
                    return;
            }
        });

        const method = choice.target.method?.toLowerCase() || 'get';
        const isJSON = Object.keys(jsonVars).length > 0;
        const queryVarsGet = Object.keys(queryVars).map(
            qv => '?' + qv + '=' + encodeURIComponent(queryVars[qv])
        );
        return {
            headers: {
                'Content-Type': isJSON ? 'application/json' : 'text/plain',
            },
            method,
            url:
                method === 'get'
                    ? urlJoin([targetUrl, ...queryVarsGet])
                    : targetUrl,
            data: isJSON ? JSON.stringify(jsonVars) : queryVars,
        } as AxiosRequestConfig;
    }

    async runChoice(choice: ExternalValidationFormChoice): Promise<void> {
        if (choice) {
            this.error = false;
            if (choice.target) {
                this.loading++;
                try {
                    await axios(this.buildChoiceTargetConfig(choice));
                    this.changeShopContentModifiers(choice.shop_content);
                    this.nextPage();
                } catch (e) {
                    this.error = true;
                } finally {
                    this.loading--;
                }
            } else {
                this.changeShopContentModifiers(choice.shop_content);
                this.nextPage();
            }
        }
    }

    nextPage(): void {
        this.$emit('next');
    }
}
