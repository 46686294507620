










































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import {
    SeatsChartRendererConfigOptions,
    SeatsExtraChartRendererConfigOptions,
    SeatsInfoEvent,
    SeatsValidationEvent,
    Ticket,
} from '@openticket/sdk-shop';

@Component
export default class SeatsModal extends Vue {
    @Prop() ticket!: Ticket;

    seatsInfo: SeatsInfoEvent | null = null;
    seatsLabel = '';
    isValid = true;

    created(): void {
        this.updateSeatsInfo();

        this.$shop.cart.on(['ticket'], () => {
            this.updateSeatsInfo();
        });

        this.$shop._eventBus.on(
            ['seats', 'validation'],
            (path: string[], data: SeatsValidationEvent) => {
                this.isValid = data.valid;
            }
        );

        this.$shop.cart.on(
            ['timer', 'clear'],
            (path: string[], data: { timeout: boolean }) => {
                if (data.timeout) {
                    this.close();
                }
            }
        );
    }

    async mounted(): Promise<void> {
        const loading = `
    <div class="seats-loader">
        <div class="spinner"></div>
        <h5>Loading...</h5>
    </div>`;

        const inputOptions: SeatsChartRendererConfigOptions &
            SeatsExtraChartRendererConfigOptions = {
            loading,
        };

        if (this.$settings) {
            inputOptions.showLegend = !this.$settings.static.shop.seats
                .hideLegend;

            inputOptions.hideSectionsNotForSale = !!this.$settings.static.shop
                .seats.hideSectionsNotForSale;

            inputOptions.objectTooltip = {
                showActionHint: !this.$settings.static.shop.seats.tooltip
                    .hideActionHint,
                showAvailability: !this.$settings.static.shop.seats.tooltip
                    .hideAvailability,
                showCategory: !this.$settings.static.shop.seats.tooltip
                    .hideCategory,
                showLabel: !this.$settings.static.shop.seats.tooltip.hideLabel,
                showPricing: !this.$settings.static.shop.seats.tooltip
                    .hidePricing,
                showUnavailableNotice: !this.$settings.static.shop.seats.tooltip
                    .hideUnavailableNotice,
            };
        }

        await this.$shop.cart.addTicketThroughSeats(
            this.ticket.guid,
            '#seats__floorplan',
            inputOptions
        );
    }

    close(): void {
        this.$shop.cart.closeSeats();

        this.$emit('close');
    }

    updateSeatsInfo(): void {
        this.seatsInfo = this.$shop.cart.getSeatedTicketInfo(this.ticket.guid);

        if (!this.seatsInfo.items || !this.seatsInfo.items.length) {
            this.seatsLabel = '';
        }

        this.seatsLabel = this.seatsInfo.items
            .map(item => item.label)
            .join(', ');
    }
}
