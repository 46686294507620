


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CookieWallSection extends Vue {
    @Prop({ required: true, type: String })
    title!: string;

    @Prop({ default: '', type: String })
    description!: string;
}
