
import { Component, Prop, Watch } from 'vue-property-decorator';
import {
    Ticket,
    Event,
    CartTicket,
    CartItemsEvent,
} from '@openticket/sdk-shop';
import { openModal } from '../../../utils';
import SeatsModal from '../modals/Seats.vue';
import ShopGroupItem from './ShopGroupItem.vue';

@Component
export default class EventGroupItemTicket extends ShopGroupItem {
    @Prop() event!: Event;
    @Prop() ticket!: Ticket;

    ticketListener!: string;

    created(): void {
        this.initTicket();
    }

    initTicket(): void {
        if (this.ticketListener) {
            this.$shop.off(this.ticketListener);
        }

        this.modelType = 'ticket';
        this.guid = this.ticket.guid;

        this.title = this.ticket.name;
        this.pricing = this.ticket.pricing.self;
        this.description = this.ticket.description;
        this.status = this.ticket.status;
        this.available_from = this.ticket.available_from;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.groupSize = this.ticket.increment || 1;

        if (this.ticket.seated) {
            this.type = 'seated';
        }

        const data = this.$shop.cart.getTicketInfo(this.ticket.guid);
        this.update(data);

        this.ticketListener = this.$shop.cart.on(
            ['ticket', this.ticket.guid],
            (path: string[], event: CartItemsEvent) => this.update(event)
        );

        // Start countdown if we are within the available_from margin
        if (
            !this.ticket.hidden &&
            this.ticket.status === 'not_sold_right_now' &&
            this.ticket.available_from
        ) {
            let interval: number | null = null;

            const updateCountdown = () => {
                if (!interval) {
                    return;
                }

                if (!this.ticket.available_from) {
                    window.clearInterval(interval);
                    return;
                }

                const msToAvailable =
                    this.ticket.available_from.getTime() - Date.now();

                if (msToAvailable < 0 && this.countdown && this.countdown > 0) {
                    this.countdown = 0;
                    window.clearInterval(interval);
                    return;
                }

                this.countdown = Math.ceil(msToAvailable / 1000);
            };

            interval = window.setInterval(updateCountdown, 1000);
            updateCountdown();
        }
    }

    protected async open(): Promise<void> {
        openModal({
            component: SeatsModal,
            parent: this,
            width: '100%',
            props: {
                ticket: this.ticket,
            },
        });
    }

    protected add(): Promise<CartItemsEvent> {
        return this.$shop.cart.addTicket(this.ticket.guid);
    }

    protected remove(): Promise<CartItemsEvent> {
        return this.$shop.cart.removeTicket(this.ticket.guid);
    }

    private getTickets(): CartTicket[] | null {
        const event = this.$shop.cart.items[this.event.guid];

        if (!event) {
            return null;
        }

        const tickets = event.tickets.map[this.ticket.guid];

        if (!tickets) {
            return null;
        }

        return Object.values(tickets || {});
    }

    @Watch('ticket', { deep: true })
    onTicketChange(): void {
        this.initTicket();
    }
}
