import { CookieMessages, PartialCookieMessages } from './types';

export const defaultMessages: CookieMessages = {
    configure: {
        acceptAll: {
            text: 'Accept all',
            title: 'Click to accept all categories',
        },
        confirm: {
            text: 'Accept',
            title: 'Click to accept the selected categories',
        },
        title: 'Change cookie preferences',
    },
    error: {
        close: {
            text: 'Close',
            title: 'Close the dialog',
        },
        description:
            'The application is unable to view or change cookie preferences.',
        title: 'Cookies',
    },
    landing: {
        acceptAll: {
            text: 'Accept all',
            title: 'Click to accept all categories',
        },
        configure: {
            text: 'Change cookie preferences',
            title: 'Click to see and change your cookie preferences',
        },
        description:
            "We use cookies to make your life easier by remembering what you've added to your cart so you can pick up right where you left off, as well as many other smart ways to enhance your experience.",
        title: 'Cookies',
    },
};

export function mergeMessages(
    target: CookieMessages,
    messages?: PartialCookieMessages
): void {
    target.configure.acceptAll.text = getValue(
        'text',
        defaultMessages.configure.acceptAll,
        messages?.configure?.acceptAll
    );
    target.configure.acceptAll.title = getValue(
        'title',
        defaultMessages.configure.acceptAll,
        messages?.configure?.acceptAll
    );
    target.configure.confirm.text = getValue(
        'text',
        defaultMessages.configure.confirm,
        messages?.configure?.confirm
    );
    target.configure.confirm.title = getValue(
        'title',
        defaultMessages.configure.confirm,
        messages?.configure?.confirm
    );
    target.configure.title = getValue(
        'title',
        defaultMessages.configure,
        messages?.configure
    );

    target.error.close.text = getValue(
        'text',
        defaultMessages.error.close,
        messages?.error?.close
    );
    target.error.close.title = getValue(
        'title',
        defaultMessages.error.close,
        messages?.error?.close
    );
    target.error.description = getValue(
        'description',
        defaultMessages.error,
        messages?.error
    );
    target.error.title = getValue(
        'title',
        defaultMessages.error,
        messages?.error
    );

    target.landing.acceptAll.text = getValue(
        'text',
        defaultMessages.landing.acceptAll,
        messages?.landing?.acceptAll
    );
    target.landing.acceptAll.title = getValue(
        'title',
        defaultMessages.landing.acceptAll,
        messages?.landing?.acceptAll
    );
    target.landing.configure.text = getValue(
        'text',
        defaultMessages.landing.configure,
        messages?.landing?.configure
    );
    target.landing.configure.title = getValue(
        'title',
        defaultMessages.landing.configure,
        messages?.landing?.configure
    );
    target.landing.description = getValue(
        'description',
        defaultMessages.landing,
        messages?.landing
    );
    target.landing.title = getValue(
        'title',
        defaultMessages.landing,
        messages?.landing
    );
}

export function getValue<Key extends string>(
    key: Key,
    fallback: { [K in Key]: string },
    value?: null | Partial<{ [K in Key]: string }>
): string {
    return value?.[key] || fallback[key];
}
