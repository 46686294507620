var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-item",class:[_vm.status, _vm.groupItemModelModifier, _vm.groupItemSelectorClass]},[_c('div',{staticClass:"group-item__container"},[_c('div',{staticClass:"group-item__info ot-clickable",class:{ 'group-item__info--description': _vm.description },on:{"click":function($event){_vm.description && !_vm.disableDescription
                    ? (_vm.showDescription = !_vm.showDescription)
                    : null}}},[_c('span',{staticClass:"ot-text-body-strong"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.description && !_vm.disableDescription)?_c('img',{staticClass:"group-item__info__btn",attrs:{"src":require("@/assets/images/icons/info.svg"),"alt":_vm.$t(
                            'shop.components.shop_group_item.description.info_hover'
                        )}}):_vm._e()]),_c('span',{key:("group-item__info__price.pricing.total_price." + (_vm.pricing.total_price)),staticClass:"ot-text-tiny"},[_vm._v(" "+_vm._s(_vm.$t(_vm.itemPriceSlug, _vm.pricingStrings))+" "+_vm._s(_vm.itemFeeSlug ? _vm.$t(_vm.itemFeeSlug, _vm.pricingStrings) : '')+" ")])]),_c('div',{staticClass:"group-item__right"},[(_vm.status === 'available' || _vm.count > 0)?_c('div',[(_vm.type === 'default')?_c('div',{key:("group-item__actions.count." + _vm.count),staticClass:"group-item__actions"},[_c('button',{staticClass:"ot-button is-round is-tiny group-item__actions__remove",attrs:{"disabled":_vm.count < 1},on:{"click":_vm._remove}},[_c('i',{staticClass:"oti oti-minus is-small",attrs:{"alt":_vm.$t(
                                    'shop.components.shop_group_item.addition.remove'
                                )}})]),_c('span',[_vm._v(_vm._s(_vm.totalCount))]),_c('button',{staticClass:"ot-button is-round is-tiny group-item__actions__add",attrs:{"disabled":_vm.count > 0 && _vm.status !== 'available'},on:{"click":_vm._add}},[_c('i',{staticClass:"oti oti-plus is-small",attrs:{"alt":_vm.$t(
                                    'shop.components.shop_group_item.addition.add'
                                )}})])]):(_vm.type === 'seated')?_c('div',{staticClass:"group-item__actions"},[_c('button',{staticClass:"ot-button is-small",on:{"click":_vm.open}},[_vm._v(" "+_vm._s(_vm.$t( 'shop.components.shop_group_item.seated.select' ))+" ")])]):_vm._e()]):_vm._e(),((_vm.status === 'available' || _vm.count > 0) && !_vm.isLoading)?_c('div',{key:("group-item__price.count." + _vm.count + ".price." + _vm.price),staticClass:"group-item__price"},[(_vm.price > 0)?_c('span',{staticClass:"group-item__price__split"},_vm._l((_vm.counts),function(count,price){return _c('span',{key:price},[_vm._v(_vm._s(count)+" x "+_vm._s(_vm.$l.currency( parseInt(price), _vm.$shop.data.currency )))])}),0):_vm._e(),_c('span',{staticClass:"group-item__price__total",class:{
                        disabled: _vm.count <= 0,
                    }},[_vm._v(_vm._s(_vm.$t(_vm.totalPriceSlug, _vm.pricingStrings)))])]):(_vm.countdown)?_c('div',{key:("group-item__status.countdownString." + _vm.countdownString),staticClass:"group-item__status"},[_vm._v(" "+_vm._s(_vm.countdownString)+" ")]):(
                    !_vm.countdown && _vm.count < 1 && _vm.status !== 'available'
                )?_c('div',{staticClass:"group-item__status"},[_c('h5',[_vm._v(_vm._s(_vm.$t(_vm.statusSlug)))])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"group-item__loading"},[_c('div',{staticClass:"spinner"})]):_vm._e()])]),_c('Collapse',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDescription),expression:"showDescription"}],on:{"click":function($event){_vm.showDescription = false}}},[_c('div',{staticClass:"group-item__description__container"},[_c('div',{staticClass:"group-item__description__content",domProps:{"innerHTML":_vm._s(_vm.description)}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }