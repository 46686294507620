
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
    CartTicket,
    Product,
    ProductGroup,
    ShopProduct,
} from '@openticket/sdk-shop';

@Component
export default class OptionalProductGroupSelectItem extends Vue {
    @Prop() ticket!: CartTicket;
    @Prop() productGroup!: ProductGroup;
    @Prop() triggerUpdate!: unknown;

    loading = false;

    selected: string | null = null;

    productListener!: string;

    created(): void {
        this.productListener = this.$shop.cart.on(
            ['ticket', this.ticket.id, 'product'],
            () => {
                this.updateSelected();
            }
        );

        this.updateSelected();
    }

    destroyed(): void {
        if (this.productListener) {
            this.$shop.cart.off(this.productListener);
        }
    }

    get options(): { [key: string]: string } {
        const map: { [key: string]: string } = {};
        for (const product of this.productGroup.products) {
            map[product.guid] =
                product.item.name + ` ( ${this.suffix(product.item)} )`;
        }
        return map;
    }

    updateSelected(): void {
        for (const product of this.productGroup.products) {
            for (const cartProduct of this.ticket.products.collection) {
                if (product.guid === cartProduct.item.guid) {
                    this.selected = product.guid;

                    return;
                }
            }
        }

        this.selected = null;
    }

    suffix(product: Product): string {
        // The pricing should come from the ShopProduct wrapper,
        // the GroupProduct item's pricing is static,
        // for container products, the ShopProduct's pricing will change
        // depending on the products in the cart!
        const price = (
            this.ticket.item.products.all.find(
                (ticketProduct: ShopProduct) =>
                    ticketProduct.guid === product.guid
            ) || product
        ).pricing.total_price;

        return this.$l.currency(price, this.$shop.data.currency as string);
    }

    @Watch('triggerUpdate')
    onTriggerUpdate(): void {
        this.updateSelected();
    }

    onInput(guid: string | null): void {
        if (!guid) {
            // The select input itself does not have this option, so there is no remove event emitted.
            return;
        }

        this.$emit('add', guid, this.selected);
    }
}
